<template>
	<div>
		<v-col cols="12">
			<v-card>
				<v-card-actions>
					<v-row>
						<v-col>
							<v-col lg="6">
								<s-select-definition
									label="Area"
									:def="1281"
									:dgr="39"
									v-model="TypeArea"
									@input="loadDataInitial($event)"
								/>
							</v-col>
						</v-col>
						<v-col>
							<v-col lg="6">
								<s-select-definition
									label="Tipo Cultivo"
									:def="1172"
									v-model="TypeCrop"
									@input="loadTypeCrop($event)">
									
								</s-select-definition>
							</v-col>
						</v-col>
						<v-col cols="12" v-for="item in Object.keys(data)" >
							<h2 class="mb-3" v-if="item !== 'null'">{{ item }}</h2>
							<h2  class="mb-3" v-if="item == 'null'">Sin Presentación en Proceso.</h2>
							<v-divider></v-divider>
							<v-data-table
								:headers="headers"
								v-model="selectedPresentation"
								:items="itemsPresentation"
								:single-select="singleSelect"
								show-select
								item-key="TrpID">

								<!-- <template v-slot:item.Helper="{row}">
									{{ row.Helper +1  }}
								</template> -->
								
							</v-data-table>
							<v-divider></v-divider>
							<h2>Lotes</h2>
							<v-data-table
								:headers="headersLot"
								v-model="selectedPresentationLot"
								:items="itemsLot"
								:single-select="singleSelectLot"
								show-select 
								item-key="RcfID">
									<template v-slot:item.RcfID="{ item }">
										{{ item.RcfID + "-" +  item.RcfCode }}
									</template>
							</v-data-table>	

							<v-divider></v-divider>
							<v-row>
								<v-col cols="12">
									<v-card outlined>
									<v-card-title>
										<v-row>
											<v-col
												
											>
												<h5>Cliente:</h5>
												<h2>
													{{
														PresentationCheck.CustomerName
													}}
												</h2>
											</v-col>
											
											<v-divider vertical></v-divider>
											<v-col
												
											>
												<h5>Presentación:</h5>
												<h2>
													{{
														PresentationCheck.TypeBoxPresentationText
													}}  | {{PresentationCheck.TypePresentationPackingName}}
												</h2>
											</v-col>
											<v-divider vertical></v-divider>
											<v-col
												
											>
												<h5>Cultivo:</h5>
												<h2>
													{{
														PresentationCheck.TypeCultiveText
													}}
												</h2>
											</v-col>
											<v-divider vertical></v-divider>
											<v-col
												
											>
												<h5>Variedad:</h5>
												<h2>
													{{
														PresentationCheck.VrtDescription
													}}
												</h2>
											</v-col>
											<v-divider vertical></v-divider>
											
										</v-row>
									</v-card-title>
									<v-divider class="mx-4"></v-divider>
									
								</v-card>
								</v-col>
								
							</v-row>
						</v-col>

						<v-col cols="12">
							<v-card-actions>
											<v-col cols="12">
												<v-row>
													<v-col cols="6"> </v-col>
												</v-row>
												<v-row>
													<v-col
														cols="4"
														align="center"
														justify="center">
														<h1>Cant. Etiquetas</h1>

														<h1 
															class="mt-0"
															style="
																font-size: 150px;
															">
															{{ Math.ceil(CountLabelsPrint)}}
														</h1>
														
													</v-col>
													<v-col
														cols="4"
														align="center"
														justify="center"
													>
														<h1>N° Cajas</h1>
														<h1
															class="mt-0"
															style="
																font-size: 150px;
															"
														>
															{{PresentationCheck.TpsAmount}}
														</h1>
													</v-col>
													<v-divider vertical></v-divider>
													<v-col
														cols="4"
														align="center"
														justify="center"
													>
														<h1>Ticket Impresos</h1>
														<h1
															class="mt-0"
															style="
																font-size: 150px;
															"
														>
															{{PresentationCheck.TpsAmountPrintReal}}
														</h1>
													</v-col>
												</v-row>
												<v-row>
													<v-col
														cols="4"
														class="pt-0"
													>
														<s-text
															label="Cantidad"
															v-model="PresentationCheck.TpsAmountPrint"
															number
															:min="0"
															ref="TpsAmountPrint"
														>
														</s-text>
													</v-col>
													<v-col
														cols="4"
														class="pt-0">
														<s-text
															label="Devolución"
															v-model="PresentationCheck.TpsDiscount"
															number
															:min="0"
															ref="TpsDiscount">

														</s-text>
													</v-col>
													<v-col cols="4">
														<v-btn
															color="primary"
															block
															title="Imprimir"
															@click="save()"
														>
															Guardar
														</v-btn>
													</v-col>
												</v-row>
											</v-col>
										</v-card-actions>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-col>
		<!-- style="display: none" -->
		<v-row justify="center" cols="12" >
			<v-col cols="12" v-for="i in itemLabel">
				{{i.xNumber}}
				<qr-code
					:text="
						i.xNumber
					"
					error-level="H"
					:size="150"
					hidden
					
				>
				</qr-code
				><br />
			</v-col>
		</v-row>

	</div>
</template>

<script>
	import _sPrfTagPresentationSupervisorService from "../../../services/FreshProduction/PrfTagPresentationSupervisorService";
	import qrCode from "vue-qr-generator";

	export default {
		components: {
			qrCode,
		},

		data() {
			return {
				data: [],
				itemsPresentation: [],
				headers: [
					{text: "Cliente", value: "CustomerName"},
					{text: "Presentación", value: "TypeBoxPresentationText"},
					{text: "Presentación", value: "TypePresentationPackingName"},
					{text: "Cultivo", value: "TypeCultiveText"},
					{text: "Variedad", value: "VrtDescription"},
					{text: "Tipo Cultivo", value:"TypeCropName"},
					{text: "Helper", value: "TypeBoxPresentationHelper"}
				],
				selectedPresentation: [],
				selectedPresentationLot : [],
				PresentationCheck: {},
				PresentationCheckLot : {},
				/* TagPresentationSupervisor: {

					}, */
				text: "",
				TpsAmountPrint: "",
				active: false,
				TypeArea: 0,
				TypeCrop : 0,
				isvisi : true,
				PresentationValue : 0,
				itemDetail: {},
				singleSelect: true,
				singleSelectLot: true,
				CountLabelsPrint: 0,
				LppID: 0,
				textqr: "",
				itemLabel: [],
				TypeDestinyNew: null,

				headersLot: [
					// {text: "Pallet", value: "LlpID"},
					{text: "Lote", value: "RcfID"},
					{text: "Fundo", value: "FagName"},
					{text: "Productor", value: "PrdCardName"},
					// {text: "Pallet", value:"LppID"},
					{text: "Tipo Cultivo", value: "TypeCultiveName"},
					{text: "Cultivo", value: "TypeCropName"},
					{text: "Variedad", value: "VrtName"},
					// {text: "Jabas", value: "LppQuantityJaba"},
					{text: "Kilos", value: "TotalWeight"}
				],
				itemsLot : [],

				/* TpsAmount: 0, */
				/* min: 0, */
				/* files: [], */

				/* url: null, */
				/* image: null, */
				/* existData: false */
			};
		},

		watch: {
			selectedPresentation() {

				if(this.selectedPresentation.length > 0)
				{
					this.PresentationValue = 0;
					this.PresentationCheck = this.selectedPresentation[0];
					console.log('this.PresentationCheck ', this.PresentationCheck);
					this.active = true;


					this.PresentationValue = parseInt(this.PresentationCheck.TypeBoxPresentationHelper);
					console.log('cantidad de presentacion david',this.PresentationValue);

					if(this.PresentationCheck.itemsDetail.length > 0)
					{
						this.itemDetail = this.PresentationCheck.itemsDetail[0];
					}
				}
			},

			PresentationCheckLot(){
				this.CountLabelsPrint = (parseFloat(this.selectedPresentationLot[0].TotalWeight) / (this.PresentationValue));
			},


			selectedPresentationLot() {

			
				if(this.selectedPresentationLot.length > 0) {
					this.PresentationCheckLot = this.selectedPresentationLot[0];
					console.log('probamos para David', this.selectedPresentationLot[0])
					this.RcfID = this.selectedPresentationLot[0].RcfID;
					this.LppID = this.selectedPresentationLot[0].LppID;
				}

				if(this.selectedPresentationLot.length > 0  && this.PresentationValue > 0) {
					console.log('cantidad de kilos de lote david', this.selectedPresentationLot[0].TotalWeight);
					this.CountLabelsPrint = (parseFloat(this.selectedPresentationLot[0].TotalWeight) / (this.PresentationValue)); +
					console.log('cantidad etiquetas david', this.CountLabelsPrint);

					
				}

			}
		},

		methods: {
			/* Preview_image() {
					this.url= URL.createObjectURL(this.image)
				}, */



			// metodos para traer los lotes 
			loadLots() {
				let obj = {
					TypeCrop : this.TypeCrop,
					TypeDestiny: this.TypeDestinyNew};
				_sPrfTagPresentationSupervisorService.listLot(obj, this.$fun.getUserID())
				.then((r)=>{
					if(r.status == 200) {
						console.log('lotes calibradossss', r.data)
						this.itemsLot = r.data;
						
					}
				});
			},

			loadTypeCrop(item) {

					console.log('typeCrop', item)
					this.TypeCrop = item
					console.log('info del data',this.data);
					this.loadLots();
				
			},

			loadDataInitial(item) {

				console.log('que datos me trae', item)

				if(item !== undefined) {
					
					this.TypeDestinyNew = item;
					console.log('area de empaque David',item);
					_sPrfTagPresentationSupervisorService
					.list(item, this.$fun.getUserID())
					.then((resp) => {
						if (resp.status == 200) {
							let items = resp.data;
							this.itemsPresentation = resp.data;
							console.log('aaaaaaaaaaaaa ', resp.data);
							this.data = _.groupBy(items, "TypeAreaName");
							this.itemDetail = {}
							/* if(items.length > 0) {
								if(items[0].itemsDetail.length > 0) {
									this.itemDetail = items[0].itemsDetail[0];
									console.log("ffffffffffffff", this.itemDetail);
								}
							} */
							
						}
					});
				}
				
			},

			save() {

				this.generatedLabel();
				this.PresentationCheck.RcfID = this.RcfID;
				this.PresentationCheck.LppID = this.LppID;
				
				if(this.PresentationCheck.TpsAmountPrint > 0) {

					this.PresentationCheck.TpsPrintWeight = (this.PresentationValue * this.PresentationCheck.TpsAmountPrint)   
				}

				if(this.PresentationCheck.TpsDiscount > 0) {

					this.PresentationCheck.TpsWeightDiscount = (this.PresentationValue * this.PresentationCheck.TpsDiscount)
				}
				

				if (this.PresentationCheck.TpsAmountPrint == 0 && this.PresentationCheck.TpsDiscount == 0 ) {
					this.$fun.alert("Ingrese cantidad de etiquetas o descuento", "warning");
					//this.$refs.TpsAmountPrint.focus();
					return;
				}


				if(this.PresentationCheck.TpsAmountPrint > this.CountLabelsPrint) {
					this.$fun.alert("La cantidad ingresada excede", "warning");
					return;
				}


				// if (this.PresentationCheck.TpsAmountPrint > this.PresentationCheck.TpsAmount) {
				// 	this.$fun.alert(
				// 		"La cantidad excede el numero de cajas",
				// 		"warning"
				// 	);
				// 	//this.$refs.TpsAmountPrint.focus();
				// 	return;
				// }

				this.$fun.alert("¿Seguro de guardar?", "question").then((val) => {
					if (val.value) {

						
						//item.TpsAmountPrint = this.TpsAmountPrint;
						this.PresentationCheck.UsrUpdateID = this.$fun.getUserID();

						_sPrfTagPresentationSupervisorService
							.save(
								this.PresentationCheck,//this.TagPresentationSupervisor,
								this.$fun.getUserID()
							)
							.then((resp) => {
								if (resp.status == 200) {
									this.loadDataInitial();
									// this.printLabelAll();
									this.$fun.alert("Registros guardados correctamente", "success");
									this.loadLots();
									this.selectedPresentationLot = [];
									this.selectedPresentation = [];
									this.CountLabelsPrint = 0;
									//this.TpsAmountPrint = 0;
								}
							});
					}
				});
			},

			generatedLabel()
			{
				this.itemLabel = [];
				let initial = 1;
				let end = this.PresentationCheck.TpsAmount;

				for (let index = initial; index <= end; index++) {
					let obj = {xNumber: index.toString()};
					this.itemLabel.push(obj);
				}
			},

			printLabelAll()
			{
				var imgAll =  []
                imgAll = document.querySelectorAll("img");

				if(imgAll.length > 0){

                    var Pagelink = "about:Empacadora";
                    var pwa = window.open(Pagelink, "_new");
                    pwa.document.open();
                    pwa.document.write(
                        "<html><head><scri" +
                            "pt>function step1(){\n" +
                            "setTimeout('step2()', 1);}\n" +
                            "function step2(){window.print(); window.close()}\n" +
                            "</scri" +
                            "pt><style media='print'>  @media print  { @page { margin-left: 1cm; {} </style></head><body onload='step1()'> \n" +
                            ""
                    );

                    imgAll.forEach((element) => {
                        pwa.document.write(
                            "<div class='col col-4' style='margin: 20px 20px 20px 20px;'><img alt='Scan me!' style='display: block;'  src='" +
                                element.currentSrc +
                                "' /></div>"
                        );
                    });
                    pwa.document.write("</div></body></html>");
                    pwa.document.close();
                }

			}

			/* printLabelAll()
				{
					if(this.TpsAmountPrint == 0){
						this.$fun.alert("Ingrese cantidad de etiquetas a imprimir", "warning");
						this.$refs.TpsAmountPrint.focus();
						return;
					}

					if(this.TpsAmountPrint > this.TpsAmount){
						this.$fun.alert("La cantidad a imprimir excede el numero de cajas", "warning");
						this.$refs.TpsAmountPrint.focus();
						return;
					}

					this.$fun.alert("¿Seguro de imprimir?", "question")
					.then(val => {
						if(val.value){
							var Pagelink = "about:blank";
							var pwa = window.open(Pagelink, "_new");
							pwa.document.open();
							pwa.document.write("<html><head><scri" + "pt>function step1()		{\n" +
								"setTimeout('step2()', 10);}\n" +
								"function step2(){window.print();window.close()}\n" +
								"</scri" + "pt></head><body onload='step1()'> \n" +
								"<div class='col col-12 justify-center'>");

							for (let index = 0; index < this.TpsAmountPrint; index++) {
								pwa.document.write("<div class='col col-4' style='margin: 20px 20px 20px 20px;'><img alt='Scan me!' style='display: block;'  src='" + this.url + "' /></div>");
							}

							
							
							pwa.document.write('</div></body></html>');
							pwa.document.close();
							
							this.TagPresentationSupervisor.TpsAmountPrint = this.TpsAmountPrint;
							this.TagPresentationSupervisor.UsrUpdateID = this.$fun.getUserID();

							_sPrfTagPresentationSupervisorService.save(this.TagPresentationSupervisor, this.$fun.getUserID())
							.then(resp => {
								if(resp.status == 200){
									this.loadDataInitial();
								}
							})

						}
					})

					
			
				}, */
			/* var imgAll = document.querySelectorAll("img");
	            
					var Pagelink = "about:blank";
					var pwa = window.open(Pagelink, "_new");
					pwa.document.open();
					pwa.document.write("<html><head><scri" + "pt>function step1(){\n" +
									"setTimeout('step2()', 10);}\n" +
									"function step2(){window.print();window.close()}\n" +
									"</scri" + "pt></head><body onload='step1()'> \n" +
									"<div class='col col-12 justify-center'>");

					imgAll.forEach(element => {
						pwa.document.write("<div class='col col-4' style='margin: 20px 20px 20px 20px;'><img alt='Scan me!' style='display: block;'  src='" + element.currentSrc + "' /></div>");
						
					});
					pwa.document.write('</div></body></html>');
					pwa.document.close(); */
		},

		created() {
			this.loadDataInitial();
			this.loadLots();
			this.loadTypeCrop();
		},

		mounted() {
			//this.$refs.TpsAmountPrint.focus();
		},
	};
</script>


